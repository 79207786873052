<template>
  <div>
    <h4 class="text-center mb-4">{{ template.name }} ({{ template.id }})</h4>
    <div class="row">
      <div
        v-for="metric in template.metrics"
        :key="metric.id"
        class="col-lg-6 mx-auto mb-4"
      >
        <div class="text-center">
          <h4 class="mb-1">{{ metric.name }}</h4>
          <p v-text="metric.described"></p>
        </div>
        <div v-if="loading" class="text-center">
          <b-spinner variant="primary" />
        </div>
        <div v-else>
          <MetricDiagram
            :metric="currentMetricData(diagramData, metric.name)"
          />
        </div>
      </div>
    </div>
    <!-- <pre>template: {{ template }}</pre> -->
  </div>
</template>

<script>
import axios from "axios";
import MetricDiagram from "@/components/Metric-diagram";

export default {
  components: {
    MetricDiagram,
  },
  props: {
    template: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      diagramData: "",
    };
  },
  created() {
    this.loadData();
  },
  computed: {},
  methods: {
    async loadData() {
      this.loading = true;

      try {
        await this.loadMetricData();
      } catch (error) {
        console.log("loadData Error: ", error);
      } finally {
        this.loading = false;
      }
    },
    async loadMetricData() {
      const formData = new FormData();      

      // // Get date range - current month
      // let date_today = new Date();

      // let first_day_of_the_month = new Date(
      //   date_today.getFullYear(),
      //   date_today.getMonth(),
      //   1
      // );

      // // formData.append("start_date", "2022-07-01");
      // // formData.append("end_date", "2022-07-12");
      // formData.append("start_date", this.formatDate_YYYY_MM_DD(first_day_of_the_month));
      // formData.append("end_date", this.formatDate_YYYY_MM_DD(date_today));

      try {
        const response = await axios.post(
          this.$urls.URL_GET_METRIC + `/${this.template.id}`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );
        // console.log("response: ", response);
        this.diagramData = response.data;
      } catch (error) {
        console.log("loadMetricData, error: ", error);
      }
    },
    currentMetricData(data, name) {
      // filter only current metric data = name
      // return data;
      return data.map((d) => {
        const metrics = d.metrics.filter((m) => m[0].name === name);

        return {
          ...d,
          metrics,
        };
      });
    },
    // formatDate_YYYY_MM_DD: (date) => {
    //   var d = new Date(date),
    //     month = "" + (d.getMonth() + 1),
    //     day = "" + d.getDate(),
    //     year = d.getFullYear();

    //   if (month.length < 2) month = "0" + month;
    //   if (day.length < 2) day = "0" + day;

    //   return [year, month, day].join("-");
    // },
  },
};
</script>

<style lang="scss" scoped></style>
