<template>
  <div>
    <!-- <pre>metric: {{metric}}</pre> -->
    <apexchart
      v-if="diagramData"
      class="apex-charts"
      height="380"
      type="line"
      :series="diagramData.series"
      :options="diagramData.chartOptions"
    />
    <div v-else class="text-center">No has data...</div>
  </div>
</template>

<script>
// const valuesTST = [3, 3, 3, 0, "3", "3", "3", 0, "45", "45"];

// const datesTST = [
//   "2022-09-14 09:44:10",
//   "2022-09-14 10:02:50",
//   "2022-09-14 10:04:47",
//   "2022-09-14 10:05:55",
//   "2022-09-14 10:14:53",
//   "2022-09-14 10:18:10",
//   "2022-09-14 10:52:57",
//   "2022-09-14 12:40:56",
//   "2022-09-14 12:43:10",
//   "2022-09-14 12:45:42",
// ];

export default {
  props: {
    metric: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },

  computed: {
    diagramData() {
      if (!this.metric.length) {
        return false;
      }

      // 1 dates (categories): collect in arr data, like //["1/11/2000", "2/11/2000", "3/11/2000"],
      // const dates = ["1/11/2000", "2/11/2000", "3/11/2000"];

      const dates = this.metric.map((m) => m.date);
      // const dates = this.metric.map(m=> new Date(m.date).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}));
      // console.log("dates: ", dates);

      // 2 values (): collect value, like [4, 3, 10],
      // const values = [40, 40, 40];
      // !!! parse all to NUMBER: "", null ---> 0
      const values = this.metric.map((m) => Number(m.metrics[0][1].value));

      // check on emptiness arr values
      if (values.every((element) => element === null)) {
        return null;
      }

      return {
        chartOptions: {
          chart: {
            shadow: {
              enabled: false,
              color: "#bbb",
              top: 3,
              left: 2,
              blur: 3,
              opacity: 1,
            },
          },
          stroke: {
            width: 5,
            curve: "smooth",
          },
          xaxis: {
            type: "time", // datetime
            // categories: ["1/11/2000", "2/11/2000", "3/11/2000"],
            categories: dates,
            // categories: datesTST,
          },
          title: {
            text: "",
            align: "left",
            style: {
              fontSize: "14px",
              color: "#666",
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              gradientToColors: ["#f1556c"],
              shadeIntensity: 1,
              type: "horizontal",
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 4,
            opacity: 0.9,
            colors: ["#56c2d6"],
            strokeColor: "#fff",
            strokeWidth: 2,
            style: "inverted", // full, hollow, inverted
            hover: {
              size: 7,
            },
          },
          yaxis: {
            min: Math.min(...values) - 10, // set min from values
            // min: Math.min(...valuesTST) - 10, // set min from values
            max: Math.max(...values) + 10, // set max from values
            // max: Math.max(...valuesTST) + 10, // set max from values
            title: {
              text: "Value",
            },
          },
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: {
                  toolbar: {
                    show: false,
                  },
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
        series: [
          {
            name: "Value",
            // data: valuesTST,
            // data: [4, 3, 10],
            data: values,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
