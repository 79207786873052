<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12 mx-auto">
        <div class="card">
          <div v-if="loading" class="card-body">
            <div class="text-center">
              <b-spinner variant="primary" />
            </div>
          </div>
          <div v-else class="card-body">
            <b-alert
              v-if="reqError"
              variant="danger"
              class="my-0"
              show
              v-html="reqError"
            />

            <template v-else>
              <template v-if="templates.length">
                <!-- <pre>templates: {{ templates }}</pre> -->
                <TemplateListItem
                  v-for="template in templates"
                  :template="template"
                  :key="template.id"
                />
              </template>
              <div v-else class="text-center">
                <div class="mb-3">{{ $t("template.no-templates") }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <!-- <pre>userPermissionsIDs {{ this.$store.getters.userPermissionsIDs }}</pre> -->
      <!-- <div><pre>lang {{ this.$store.getters.lang }}</pre></div> -->
      <!-- <div></div><pre>$i18n.locale: {{ $i18n.locale }}</pre></div> -->
      <!-- <div><pre>user: {{ $store.getters.user }}</pre></div> -->
      <div class="d-none"><pre>user.owner: {{ $store.getters.user.owner }}</pre></div>
    </div>
  </Layout>
</template>

<script>
import axios from "axios";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/Page-header";
import TemplateListItem from "@/components/Template-list-item";

export default {
  metaInfo() {
    return {
      title: this.$appConfig.title + " | " + this.$t("sidebar.home"),
    };
  },
  components: {
    Layout,
    PageHeader,
    TemplateListItem,
  },
  data() {
    return {
      title: "sidebar.home",
      items: [
        {
          text: this.$appConfig.title,
          to: "/",
        },
        {
          tran: "sidebar.home",
          active: true,
        },
      ],
      loading: false,
      templates: "",
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loadTableData();
      } catch (error) {
        console.log("loadData Error: ", error);
      }
    },
    async loadTableData(silent = false) {
      if (!silent) this.loading = true;
      this.reqError = false;

      try {
        const response = await axios.get(
          this.$urls.URL_USER_TEMPLATES + `?page=1&per_page=1000`
        );
        // console.log("response: ", response);
        this.templates = response.data.data;
      } catch (error) {
        console.log("loadTableData, error: ", error);
        this.reqError = true;
        if (error.response?.data?.message) {
          this.reqError = `<b>[ERROR: ${error.response.status}]</b> ${error.response?.data?.message}`;
        }
      } finally {
        this.loading = false;
      }
    },
    confirmDeleteTemplate(item) {
      this.$bvModal
        .msgBoxConfirm(
          this.htmlToVNode(`${this.$t("modal.want-delete-template")}: ${
            item.name
          }?
          ${this.$t("modal.want-delete-template-warning")}`),
          {
            html: true,
            title: this.$t("modal.confirm-delete"),
            size: "md",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: this.$t("btn.yes"),
            cancelTitle: this.$t("btn.no"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((sure) => {
          if (sure) {
            this.deleteTemplate(item.id);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    deleteTemplate(id) {
      const formData = new FormData();
      formData.append("_method", "DELETE");

      axios
        .post(this.$urls.URL_TEMPLATES + "/" + id, formData)
        .then(() => {
          this.loadTableData();
        })
        .catch(function(error) {
          console.log(error.response);
        });
    },
    metricsToStr(item) {
      const metricsNames = item.metrics.map((m) => m.name);
      return metricsNames.length ? metricsNames.join(",\n") : "";
    },
    htmlToVNode(html) {
      return [this.$createElement("div", { domProps: { innerHTML: html } })];
    },
  },
};
</script>
